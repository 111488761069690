<template>
  <div class="register">
    <v-container class="content pb-5">
        <v-row>
          <v-col cols="12" md="5" class="hidden-sm-and-down">
            <v-img
              alt="img"
              class="img"
              src="@/assets/img/auth/img-1.jpg"
              max-width="600"
            />
          </v-col>
  
          <v-col cols="12" offset-md="1" md="6" align-self="center">
            <v-card color="pa-5 pa-md-8" rounded="xl">
                <!-- Go to login -->
              <h3 v-if="successfulRegister" class="heading-h4">
                {{ successfulRegisterMessage }}
              </h3>

              <!-- Register -->
              <h3 v-else class="heading-h3">Register</h3>

              <!-- successfulRegister -->
              <v-col cols="12" v-if="successfulRegister">
                  <v-btn
                    :to="{ name: 'Login' }"
                    class="primary mt-4"
                    width="100%"
                    rounded
                  >
                    Login
                  </v-btn>
              </v-col>

              <!-- Form -->
              <v-form ref="form" lazy-validation v-else>
                <v-row class="mt-2">
                  <v-col cols="12" class="d-flex flex-column">
                    <!-- Company Name -->
                    <v-text-field
                      v-model="companyName"
                      :rules="companyNameRules"
                      outlined
                      
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          Company Name
                        </p>
                      </template>
                    </v-text-field>                    
  
                    <!-- Company Web Site -->
                    <v-text-field
                      v-model="companyWebsite"
                      outlined
                    >
                      <template v-slot:label>
                        <p class="body-1">Company Web Site</p>
                      </template>
                    </v-text-field>
  
                    <!-- First Name -->
                    <v-text-field
                      v-model="firstName"
                      :rules="firstNameRules"
                      outlined
                      required
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          First Name <span class="red--text">*</span>
                        </p>
                      </template>
                    </v-text-field>
  
                    <!-- Last Name -->
                    <v-text-field
                      v-model="lastName"
                      :rules="lastNameRules"
                      outlined
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          Last Name <span class="red--text">*</span>
                        </p>
                      </template>
                    </v-text-field>
  
                    <!-- Email -->
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      outlined
                      required
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          Email <span class="red--text">*</span>
                        </p>
                      </template>
                    </v-text-field>
  
                    <!-- First Phone Number -->
                    <v-text-field
                      v-model="phone1"
                      :rules="phone1Rules"
                      type="tel"
                      outlined
                      required
                    >
                      <template v-slot:label>
                        <p class="body-1">
                          First Phone Number
                          <span class="red--text">*</span>
                        </p>
                      </template>
                    </v-text-field>
  
                    <!-- Second Phone Number -->
                    <v-text-field
                      v-model="phone2"
                      :rules="phone2Rules"
                      type="tel"
                      outlined                      
                    >
                      <template v-slot:label>
                        <p class="body-1">Second Phone Number</p>
                      </template>
                    </v-text-field>
  
                    <!-- Password -->
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      :type="showPassword ? 'text' : 'password'"
                      hint="At least 8 characters"
                      counter
                      outlined
                      @click:append="showPassword = !showPassword"
                      required
                    >
                      <template v-slot:label>
                        <p class="body-1">Password <span class="red--text">*</span></p> 
                      </template>
                    </v-text-field>
  
                    <!-- Confirm Password -->
                    <v-text-field
                      v-model="confirmPassword"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="confirmPasswordRules"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      counter
                      outlined
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      required
                    >
                      <template v-slot:label>
                        <p class="body-1">Confirm Password <span class="red--text">*</span></p>
                      </template>
                    </v-text-field>
  
                    <v-btn
                      @click="validate()"
                      class="primary mt-2"
                      rounded
                    >
                      Send
                    </v-btn>

                    <!-- Already have an account? Login. -->
                    <v-layout class="mt-4">
                      <span>
                        Already have an account?
                      </span>
  
                      <router-link :to="{ name: 'Login' }" class="ml-1">
                        Login.
                      </router-link>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
  
          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-container>
  </div>
</template>  

<script>
export default {
  name: 'DocumentManagementRegister',
   
  data: () => ({
    overlay: false,

    // redirect
    redirect: "/dashboard/inbox",

    // successfulRegister
    successfulRegister: false,

    // successfulRegisterMessage
    successfulRegisterMessage: "Go to login",

    // company name
    companyName: "",
    companyNameRules: [
        (v) => (v === null || v === "" || /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v)) || "Only english letters, apostrophes, and hyphens are allowed",
        (v) => (v === null || v === "" || v.length >= 3) || "Cannot be less than 3 characters",
        (v) => (v === null || v === "" || v.length <= 100) || "Cannot be more than 100 characters",
    ],

    // company address
    companyAddress: "",
    companyAddressRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // company email
    companyEmail: "",
    companyEmailRules: [
      (v) => !!v || "Required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Company e-mail must be valid",
      (v) =>
        v.length <= 255 || "Company email can not be more than 255 characters",
    ],

    // Company First Phone Number
    companyPhone1: "",
    companyPhone1Rules: [
      // (v) => !!v || "Required",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    // Company Second Phone Number
    companyPhone2: "",
    companyPhone2Rules: [
      // (v) => !!v || "Required",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    // company website
    companyWebsite: "",
    companyWebsiteRules: [
      // (v) => !!v || "Required",
      (v) =>
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
          v
        ) || "Please enter valid company website link",
      (v) =>
        v.length <= 255 ||
        "Company website can not be more than 255 characters",
    ],

    // first name
    firstName: "",
    firstNameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // last name
    lastName: "",
    lastNameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length >= 3 || "Can not be less than 3 characters",
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // email
    email: "",
    emailRules: [
      (v) => !!v || "Required",
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<script>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Invalid e-mail.";
      },
      (v) => v.length <= 100 || "Can not be more than 100 characters",
    ],

    // First Phone Number
    phone1: "",
    phone1Rules: [
      (v) => !!v || "Required",
      (v) => v.length <= 20 || "Can not be more than 20 characters",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    // Second Phone Number
    phone2: "",
    phone2Rules: [
      // (v) => !!v || "Required",
      // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      // (v) => v.length == 12 || "Mobile phone is incorrect",
    ],

    showPassword: false,

    // password
    password: "",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "Can not be less than 8 characters",
      (v) => v.length <= 30 || "Can not be more than 30 characters",
    ],

    showConfirmPassword: false,

    // confirm password
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Required",
      //   (v) => v === this.password || "The password confirmation does not match.",
    ],
  }),

  computed: {},

  created() {
    //
  },

  watch: {
    // phone1
    // phone1(val) {
    //   this.phone1 = this.$helpers.formatInputMobileNumber(val);
    // },

    // phone2
    // phone2(val) {
    //   this.phone2 = this.$helpers.formatInputMobileNumber(val);
    // },

    // companyPhone1
    // companyPhone1(val) {
    //   this.companyPhone1 = this.$helpers.formatInputMobileNumber(val);
    // },

    // companyPhone2
    // companyPhone2(val) {
    //   this.companyPhone2 = this.$helpers.formatInputMobileNumber(val);
    // },
  },

  methods: {
    // validate
    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.register();
      }
    },

    // register
    async register() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `register`,
        {
          company_name: this.companyName ? this.companyName : this.firstName + " " + this.lastName,
          company_email: this.email,
          // company_address: this.companyAddress,
          company_phone1: this.phone1,
          company_phone2: this.phone2,
          company_website: this.companyWebsite,

          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone1: this.phone1,
          phone2: this.phone2,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
        "Register successful, you can now login"
      );

      if (res.status == 200) {
        // requestVerifyEmailAfterRegister
        await this.requestVerifyEmailAfterRegister();

        this.successfulRegister = true;

        this.$refs.form.reset();
      }

      this.overlay = false;
    },

    // requestVerifyEmailAfterRegister
    async requestVerifyEmailAfterRegister() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `request-verify-email/after-register`,
        {
          email: this.email,
        },
        "A verification email has been sent you."
      );

      if (res.status == 200) {
        // successfulRegisterMessage
        this.successfulRegisterMessage = "A verification email has been sent you.";
      } else{
        // successfulRegisterMessage
        this.successfulRegisterMessage = "Go to login";
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  // min-height: 100vh;
  .content {
    margin-top: 100px;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
    }
  }
}
</style>
