<template>
    <div class="background py-16">
      <Register />
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  
  import Register from "@/components/elements/auth/register/Register";
  
  export default {
    name: "DocumentManagementRegister",
  
    components: {
      Register,
    },
  
    data() {
      return {};
    },
  
    computed: {
      ...mapState({ auth: "auth" }),
    },
  
    created() {
      if (this.auth.loggedIn) {
        this.$router.push({ name: "DashboardInbox" });
      }
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped>
  //
  </style>
  